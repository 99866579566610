<template>
  <el-card class="box-card">
    <!-- <div slot="header" class="clearfix">
      <h3 class="inBlock">门店邀约排行</h3>
    </div> -->
     <div slot="header" class="clearfix">
			<h3>
				<el-tooltip class="item" effect="dark" content="返回上一页" placement="bottom">
				    <i @click="goBack" style="margin-right: 40px; cursor: pointer;" class="el-icon-arrow-left"></i>
				</el-tooltip>
				商品销量排行
			</h3>
		</div>
        <!-- 表格内容 -->
         <el-table
            ref="multipleTable"
            :data="tableData"
            border
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column label="商品ID" prop="goods_id">
            </el-table-column>
            <el-table-column label="商品名称" prop="goods_name">
            </el-table-column>
            <el-table-column label="商品图片" width="150">
                <template class="listimg" slot-scope="scope">
                    <img :src="imgurl + scope.row.goods_pic" alt="" />
                </template>
            </el-table-column>
            <el-table-column label="直播价格" prop="live_price">
            </el-table-column>
            <el-table-column label="商品销量" prop="total_num">
            </el-table-column>
            <el-table-column label="商品成交额" prop="total_price">
            </el-table-column>
            <el-table-column label="商品转化率" prop="total_num">
                <template slot-scope="scope" v-if="scope.row.click_num > 0">
                    {{scope.row.total_num/scope.row.click_num}}%
                </template>
                <template slot-scope="scope" v-if="scope.row.click_num == 0">
                    0
                </template>
            </el-table-column>
            <!-- <el-table-column label="地址">
                <template slot-scope="scope">
                    {{scope.row.province}}-{{scope.row.city}}-{{scope.row.district}}
                </template>
            </el-table-column>
            <el-table-column label="详细地址" prop="detail">
            </el-table-column>
            <el-table-column label="备注" prop="remarks">
            </el-table-column> -->
        </el-table>
        <!-- 分页控制 -->
        <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="sizeForm.page"
        @current-change="setPage">
        </el-pagination>
        <!-- <el-dialog title="导出范围" :visible.sync="exportShow" width="25%">
            <div>
                <p style="padding: 0 0 20px 0; font-size: 12px; color: #e6a23c">
                将导出满足筛选条件的门店!
                </p>
                <el-radio v-model="exportType" :label="1">当前页</el-radio>
                <el-radio v-model="exportType" :label="2">导出所有</el-radio>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportShows">取 消</el-button>
                <el-button type="primary" @click="exportShop2">确 定</el-button>
            </span>
        </el-dialog> -->
    </el-card>
</template>
<script>
import { postLiveGoodsSale, luckdrawsaddressOutexcel } from '@/api/api.js'
import { filenameFormat } from "@/utils/index";
import { getSystemsettings } from "../../api/getSystemsetting.js";
export default {
    components:{},
    name: 'commodityRanking',
    data(){
        return{
            sizeForm: {
                limit: 10, //每页显示条数
                page: 1,
                live_id: 0, //直播间id
                playback_id: 0, //直播记录id
            },
            total: 0, //总条数
            dateTime: '', //时间区间
            tableData:[], //列表数据
            handleSelect: [], //多选数据
            idArry: '' , //多选id组
            exportShow: false,
            exportType: '',
            imgurl: ''
        }
    },
     mounted() {
        getSystemsettings.then((res) => {
        this.imgurl = res;
        });
    },
    created(){
        if (this.$route.query.liveid) {
            console.log('this.$route.query.id====',this.$route.query.liveid)
            this.sizeForm.live_id = this.$route.query.liveid
            this.sizeForm.playback_id =  this.$route.query.playback_id
        }
        this.getlist()
    },
    methods:{
        goBack() {
            console.log('go back');
            this.$router.back(-1)
        },
        // 获取评论列表
        async getlist() {
            // this.form.live_id = this.$route.query.liveid;
            const { data } = await postLiveGoodsSale(this.sizeForm);
            if (data.code != 200) return this.$message.error(data.data);
            this.tableData = data.data.data;
            this.total = data.data.total;
        },
        // 绑定新门店
        bindingShop(id){
            this.$refs['bindingShopModel'].modelShow(id)
        },
        // 子组件添加成功回调
        submitSuccess(e){
            this.$message({ type: 'success', message: e })
            this.getlist()
        },
        // 取消导出
        exportShows() {
            this.exportShow = false;
        },
        // 导出订单
        // exportShop() {
        //     this.exportShow = true;
        // },
        // 确认导出
        async exportShop() {
            if(!this.sizeForm.luckdraws_id){
                this.$message.error("请查询id筛选相应会议奖项进行导出!");
                return
            }
            let { data, types, request } = await luckdrawsaddressOutexcel(this.sizeForm, "blob");
            let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
            console.log('blob=======',blob);
            let objectUrl = URL.createObjectURL(blob); //生成下载链接
            let a = document.createElement("a"); //创建a标签用于文件下载
            a.href = objectUrl; //赋值下载路径
            a.download = filenameFormat(
              request.getResponseHeader("content-disposition")
            ); //下载的文件名称（非必填）
            // console.log('filename====',filename)
            document.body.appendChild(a); //插入DOM树
            a.click(); //点击a标签触发
            document.body.removeChild(a); //删除a标签
                // this.form.export = '';
        },
        // 查询
        onSearch(){
            this.sizeForm.page = 1
            this.getlist()
        },
        // 列表多选
        handleSelectionChange(e){
            this.handleSelect = e
            let id = []
            this.handleSelect.forEach(item => {
                id.push(item.id)
            })
            this.idArry = id.join(',')
            
        },
        // 列表多选绑定
        toggleSelectionHidden(){
            this.$refs['bindingShopModel'].modelShow(this.idArry)
        },
        // 分页控制
        setPage(e){
            this.sizeForm.page = e
            this.getlist()
        },
    }
}
</script>
<style lang="less" scope>
.app{
    padding: 30px;
}
/deep/ .el-table {
    .cell {
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
} 

</style>
<style  lang="less">

 .listimg {
    width: 50px;
  }
    img {
      width: 100%;
    }
</style>